import { parseIntMember } from '../utils/parse-functions';

export class Numbers {
  questionsiso: number;
  questions: number;
  examens: number;
  items: number;
  users: number;
  candidates: number;

  quizzAll: number;
  concoursAll: number;

  quizz: number;
  'quizz.iso': number;
  'quizz.mmg': number;
  'quizz.prepacademy': number;
  conftube: number;
  masterclass: number;
  lca: number;
  imagerie: number;
  concours: number;
  'concours.quizz': number;
  'concorso.ssm': number;
  blanche: number;
  annales: number;
  easy_dp: number;
  ecos: number;

  constructor(input: any) {
    this.quizz = parseInt(input.quizz, 10) + parseInt(input['quizz.r2c'], 10);
    this['quizz.iso'] = parseInt(input['quizz.iso'], 10) + parseInt(input['quizz.iso.r2c'], 10);
    this['quizz.mmg'] = parseInt(input['quizz.mmg'], 10) + parseInt(input['quizz.mmg.r2c'], 10);
    this['quizz.prepacademy'] = parseInt(input['quizz.prepacademy'], 10);

    this.conftube = parseInt(input.conftube, 10) + parseInt(input['conftube.r2c'], 10);
    this.masterclass = parseInt(input.masterclass, 10) + parseInt(input['masterclass.r2c'], 10);
    this.lca = parseInt(input.lca, 10) + parseInt(input['lca.r2c'], 10);
    this.imagerie = parseInt(input.imagerie, 10) + parseInt(input['imagerie.r2c'], 10);
    this.easy_dp = parseInt(input.easy_dp, 10) + parseInt(input['easy_dp.r2c'], 10);

    this.concours = parseInt(input.concours, 10) + parseInt(input['concours.r2c'], 10);
    this['concours.quizz'] =
      parseInt(input['concours.quizz'], 10) + parseInt(input['concours.quizz.r2c'], 10);
    this['concorso.ssm'] = parseInt(input['concorso.ssm'], 10);

    this.blanche = parseInt(input.blanche, 10) + parseInt(input['blanche.r2c'], 10);
    this.annales = parseInt(input.annales, 10) + parseInt(input['annales.r2c'], 10);

    this.ecos = parseInt(input.ecos, 10) + parseInt(input['ecos.r2c'], 10);

    this.quizzAll = this.quizz + this['quizz.iso'] + this['quizz.mmg'] + this['quizz.prepacademy'];
    this.concoursAll = this.concours + this['concours.quizz'] + this['concorso.ssm'];

    this.questionsiso = parseInt(input.questionsiso, 10);
    this.questions = parseInt(input.questions, 10);
    this.examens = parseInt(input.examens, 10) + parseInt(input.examensR2C, 10);
    this.items = parseInt(input.items, 10);
    this.users = parseInt(input.users, 10);
    this.candidates = parseInt(input.candidates, 10);
  }
}
