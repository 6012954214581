import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MomentModule } from 'ngx-moment';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';

import { environment } from '../../environments/environment';

import { ActionButtonsComponent } from './components/action-buttons/action-buttons.component';
import { ArticleDetailComponent } from './components/article-detail/article-detail.component';
import { BlogCommentComponent } from './components/article-detail/blog-comment/blog-comment.component';
import { BlogArticleCardComponent } from './components/cards/blog-article-card/blog-article-card.component';
import { CourseCardComponent } from './components/cards/course-card/course-card.component';
import { DashboardAncrageCardComponent } from './components/cards/dashboard-ancrage-card/dashboard-ancrage-card.component';
import { DashboardDisciplineCardComponent } from './components/cards/dashboard-discipline-card/dashboard-discipline-card.component';
import { ExamCardComponent } from './components/cards/exam-card/exam-card.component';
import { FolderDoneCardComponent } from './components/cards/folder-done-card/folder-done-card.component';
import { MultirankCardComponent } from './components/cards/multirank-card/multirank-card.component';
import { OvalHeaderCardComponent } from './components/cards/oval-header-card/oval-header-card.component';
import { ProgressAncrageTrainingCardComponent } from './components/cards/progress-ancrage-training-card/progress-ancrage-training-card.component';
import { ProgressCustomTrainingCardComponent } from './components/cards/progress-custom-training-card/progress-custom-training-card.component';
import { SubscriptionCardComponent } from './components/cards/subscription-card/subscription-card.component';
import { TestimonyCardComponent } from './components/cards/testimony-card/testimony-card.component';
import { TrainingDisciplineCardComponent } from './components/cards/training-discipline-card/training-discipline-card.component';
import { TrainingItemCardComponent } from './components/cards/training-item-card/training-item-card.component';
import { ChallengeActualitiesComponent } from './components/challenge/challenge-actualities/challenge-actualities.component';
import { ChallengeDescriptionComponent } from './components/challenge/challenge-description/challenge-description.component';
import { ChallengeMajorComponent } from './components/challenge/challenge-major/challenge-major.component';
import { ChooseFriendsComponent } from './components/choose-friends/choose-friends.component';
import { FriendInviteComponent } from './components/choose-friends/friend-invite/friend-invite.component';
import { BackgroundContentComponent } from './components/contents/background-content/background-content.component';
import { ImageContentComponent } from './components/contents/image-content/image-content.component';
import { ParagraphContentComponent } from './components/contents/paragraph-content/paragraph-content.component';
import { TextContentComponent } from './components/contents/text-content/text-content.component';
import { HistogramComponent } from './components/histogram/histogram.component';
import { BaseComponent } from './components/inherited/base/base.component';
import { LoaderBookComponent } from './components/loaders/loader-book/loader-book.component';
import { LoaderDotsComponent } from './components/loaders/loader-dots/loader-dots.component';
import { LoaderHashtagComponent } from './components/loaders/loader-hashtag/loader-hashtag.component';
import { LoaderInfiniteScrollComponent } from './components/loaders/loader-infinite-scroll/loader-infinite-scroll.component';
import { MajorTestimonyCardComponent } from './components/majors-testimonies/major-testimony-card/major-testimony-card.component';
import { MajorsTestimoniesComponent } from './components/majors-testimonies/majors-testimonies.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NotionsListComponent } from './components/notions-list/notions-list.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { PricingPremiumComponent } from './components/pricing-table/pricing-premium/pricing-premium.component';
import { QuestionCandidateFormComponent } from './components/question/question-candidate-form/question-candidate-form.component';
import { QuestionContentComponent } from './components/question/question-content/question-content.component';
import { QuestionCorrectionFormComponent } from './components/question/question-correction-form/question-correction-form.component';
import { QuestionCorrectionTopComponent } from './components/question/question-correction-top/question-correction-top.component';
import { QuestionHeaderComponent } from './components/question/question-header/question-header.component';
import { QuestionImagesComponent } from './components/question/question-images/question-images.component';
import { RankingFacTableComponent } from './components/ranking/ranking-fac-table/ranking-fac-table.component';
import { RankingTableComponent } from './components/ranking/ranking-table/ranking-table.component';
import { SiteLogoComponent } from './components/site-logo/site-logo.component';
import { SocialConnexionComponent } from './components/social-connexion/social-connexion.component';
import { StarsGradeComponent } from './components/stars-grade/stars-grade.component';
import { StudentThumbnailComponent } from './components/student-thumbnail/student-thumbnail.component';
import { TopSearchComponent } from './components/top-search/top-search.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { UserSunrayComponent } from './components/user-sunray/user-sunray.component';
import { WidgetProgressionComponent } from './components/widget-progression/widget-progression.component';

import { SafePipe } from '../shared/pipes/safe.pipe';
import { Utf8EncodePipe } from '../shared/pipes/utf8_encode.pipe';
import { AddAbbreviationsPipe } from './pipes/add-abbreviations.pipe';
import { CountdownPipe } from './pipes/countdown.pipe';
import { ParagraphizePipe } from './pipes/paragraphize.pipe';
import { RankingPipe } from './pipes/ranking.pipe';
import { RedirectionLinkPipe } from './pipes/redirection-link.pipe';
import { StripTagsPipe } from './pipes/strip-tags.pipe';

import { AccordionTableDirective } from './directives/accordion-table.directive';
import { BaseDirective } from './directives/base.directive';
import { CollapsibleElementDirective } from './directives/collapsible-element.directive';
import { CopyRestrictedDirective } from './directives/copy-restricted.directive';
import { GradientHiddenDirective } from './directives/gradient-hidden.directive';
import { ImageFallbackDirective } from './directives/image-fallback.directive';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';
import { PanelDirective } from './directives/panel.directive';

import { ModalAcceptNotificationsComponent } from './modals/modal-accept-notifications/modal-accept-notifications.component';
import { ModalAncrageInfosComponent } from './modals/modal-ancrage-infos/modal-ancrage-infos.component';
import { ModalAncrageTodayComponent } from './modals/modal-ancrage-today/modal-ancrage-today.component';
import { EndChallengeComponent } from './modals/modal-candidate-challenge/end-challenge/end-challenge.component';
import { IntroCandidateChallengeComponent } from './modals/modal-candidate-challenge/intro-candidate-challenge/intro-candidate-challenge.component';
import { IntroQuestionChallengeComponent } from './modals/modal-candidate-challenge/intro-question-challenge/intro-question-challenge.component';
import { ModalCandidateChallengeComponent } from './modals/modal-candidate-challenge/modal-candidate-challenge.component';
import { QuestionChallengeComponent } from './modals/modal-candidate-challenge/question-challenge/question-challenge.component';
import { ModalChallengeBadgeInfosComponent } from './modals/modal-challenge-badge-infos/modal-challenge-badge-infos.component';
import { ModalChangeEmailComponent } from './modals/modal-change-email/modal-change-email.component';
import { ModalChooseFolderComponent } from './modals/modal-choose-folder/modal-choose-folder.component';
import { ModalCorrectionChallengeComponent } from './modals/modal-correction-challenge/modal-correction-challenge.component';
import { ModalCourseCustomNotesComponent } from './modals/modal-course-custom-notes/modal-course-custom-notes.component';
import { DisciplineChallengeComponent } from './modals/modal-create-challenge/discipline-challenge/discipline-challenge.component';
import { ModalCreateChallengeComponent } from './modals/modal-create-challenge/modal-create-challenge.component';
import { ModalCreateExamCustomComponent } from './modals/modal-create-exam-custom/modal-create-exam-custom.component';
import { ModalEndExamComponent } from './modals/modal-end-exam/modal-end-exam.component';
import { ModalItemAbbreviationsComponent } from './modals/modal-item-abbreviations/modal-item-abbreviations.component';
import { ModalLastQuestionComponent } from './modals/modal-last-question/modal-last-question.component';
import { ModalLaunchAncrageRevisionsComponent } from './modals/modal-launch-ancrage-revisions/modal-launch-ancrage-revisions.component';
import { ModalLaunchExamComponent } from './modals/modal-launch-exam/modal-launch-exam.component';
import { ModalLaunchTrainingAncrageComponent } from './modals/modal-launch-training-ancrage/modal-launch-training-ancrage.component';
import { ModalLaunchTrainingCustomComponent } from './modals/modal-launch-training-custom/modal-launch-training-custom.component';
import { ModalListFriendsComponent } from './modals/modal-list-friends/modal-list-friends.component';
import { ModalMaintenanceComponent } from './modals/modal-maintenance/modal-maintenance.component';
import { ModalMessageComponent } from './modals/modal-message/modal-message.component';
import { ModalNewTestimonyComponent } from './modals/modal-new-testimony/modal-new-testimony.component';
import { ModalPdfFullscreenComponent } from './modals/modal-pdf-fullscreen/modal-pdf-fullscreen.component';
import { ModalPostFeedbackComponent } from './modals/modal-post-feedback/modal-post-feedback.component';
import { ModalPremiumPlusComponent } from './modals/modal-premium-plus/modal-premium-plus.component';
import { ModalPubComponent } from './modals/modal-pub/modal-pub.component';
import { ModalQuestionFeedbackComponent } from './modals/modal-question-feedback/modal-question-feedback.component';
import { ModalListImagesApplicationComponent } from './modals/modal-list-images-application/modal-list-images-application.component';
import { ModalListImagesComponent } from './modals/modal-list-images/modal-list-images.component';
import { ModalRateFolderComponent } from './modals/modal-rate-folder/modal-rate-folder.component';
import { ModalResetPasswordComponent } from './modals/modal-reset-password/modal-reset-password.component';
import { ModalSearchUserComponent } from './modals/modal-search-user/modal-search-user.component';
import { ModalStartMessageGroupComponent } from './modals/modal-start-message-group/modal-start-message-group.component';
import { ModalUpdatePaymentInfosComponent } from './modals/modal-update-payment-infos/modal-update-payment-infos.component';
import { ModalUpdateVersionComponent } from './modals/modal-update-version/modal-update-version.component';
import { ModalUserProfileComponent } from './modals/modal-user-profile/modal-user-profile.component';
import { ModalUsersInMessageGroupComponent } from './modals/modal-users-in-message-group/modal-users-in-message-group.component';
import { ModalViewRevisionComponent } from './modals/modal-view-revision/modal-view-revision.component';
import { ModalWarningComponent } from './modals/modal-warning/modal-warning.component';
import { BlockBodyScrollDirective } from './directives/block-body-scroll.directive';
import { ItemImageCardComponent } from './components/cards/item-image-card/item-image-card.component';
import { Title } from '@angular/platform-browser';
import { RandomOrderPipe } from './pipes/random-order.pipe';
import { VideoCardComponent } from './components/cards/video-card/video-card.component';
import { ModalVideoComponent } from './modals/modal-video/modal-video.component';
import { PricingBlockFeatureComponent } from './components/pricing-table/pricing-premium/pricing-block-feature/pricing-block-feature.component';
import { ModalEliteComponent } from './modals/modal-elite/modal-elite.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SelectWithSearchComponent } from './components/select-with-search/select-with-search.component';
import { PricingFeaturesTableSectionComponent } from './components/pricing-table/pricing-premium/pricing-features-table-section/pricing-features-table-section.component';
import { PricingFeaturesTableRowComponent } from './components/pricing-table/pricing-premium/pricing-features-table-row/pricing-features-table-row.component';
import { LandingTestimoniesComponent } from './components/landing-testimonies/landing-testimonies.component';
import { LandingAncrageComponent } from './components/landing-ancrage/landing-ancrage.component';
import { LandingFeedbacksComponent } from './components/landing-feedbacks/landing-feedbacks.component';
import { HeaderExamComponent } from './components/header-exam/header-exam.component';
import { ExamPreviewContentComponent } from './components/exam-preview-content/exam-preview-content.component';
import { WidgetButtonsHiddenOnTopscrollDirective } from './directives/widget-buttons-hidden-on-topscroll.directive';
import { ModalEndEcosComponent } from './modals/modal-end-ecos/modal-end-ecos.component';
import { FaqFolderDetailComponent } from './components/faq-folder-detail/faq-folder-detail.component';
import { FaqQuestionDiscussionComponent } from './components/faq-folder-detail/faq-question-discussion/faq-question-discussion.component';
import { ModalFeedbacksQuestionComponent } from './modals/modal-feedbacks-question/modal-feedbacks-question.component';
import { ExamFacRankingComponent } from './components/ranking/exam-fac-ranking/exam-fac-ranking.component';
import { ExamRankingFilterComponent } from './components/ranking/exam-ranking-filter/exam-ranking-filter.component';
import { ExamStudentRankingComponent } from './components/ranking/exam-student-ranking/exam-student-ranking.component';
import { CapitalizeFirstLetterPipe } from './pipes/capitalize-first-letter.pipe';
import { ModalTutoEcosComponent } from './modals/modal-tuto-ecos/modal-tuto-ecos.component';
import { ViewRevisionComponent } from './components/view-revision/view-revision.component';
import { PodcastCardComponent } from './components/cards/podcast-card/podcast-card.component';
import { ModalPodcastComponent } from './modals/modal-podcast/modal-podcast.component';
import { ModalJudgeQuestionRelevanceComponent } from './modals/modal-judge-question-relevance/modal-judge-question-relevance.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    MomentModule,
    MatDialogModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatRadioModule,
    MatSnackBarModule,
    MatRippleModule,
    MatSelectModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatListModule,
    NgxUsefulSwiperModule,
    MatBadgeModule,
    MatSidenavModule,
    AngularSvgIconModule,
    MatTabsModule,
    NgxExtendedPdfViewerModule,
    MatIconModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatStepperModule,
    EditorModule,
    NgxMatSelectSearchModule,
  ],
  declarations: [
    ExamCardComponent,
    TrainingDisciplineCardComponent,
    TrainingItemCardComponent,
    ProgressAncrageTrainingCardComponent,
    ProgressCustomTrainingCardComponent,
    MajorsTestimoniesComponent,
    CopyRestrictedDirective,
    Utf8EncodePipe,
    SafePipe,
    StarsGradeComponent,
    SiteLogoComponent,
    OvalHeaderCardComponent,
    CourseCardComponent,
    DashboardAncrageCardComponent,
    DashboardDisciplineCardComponent,
    MultirankCardComponent,
    BlogArticleCardComponent,
    QuestionCandidateFormComponent,
    QuestionCorrectionFormComponent,
    SocialConnexionComponent,
    NotFoundComponent,
    HistogramComponent,
    PdfViewerComponent,
    InfiniteScrollDirective,
    ModalAncrageTodayComponent,
    RankingTableComponent,
    AccordionTableDirective,
    RankingPipe,
    RankingFacTableComponent,
    ModalListImagesComponent,
    ModalListImagesApplicationComponent,
    ModalLastQuestionComponent,
    ModalLaunchExamComponent,
    ModalPubComponent,
    ModalUpdatePaymentInfosComponent,
    ModalMaintenanceComponent,
    ModalChangeEmailComponent,
    ModalAcceptNotificationsComponent,
    ModalAncrageInfosComponent,
    ModalPremiumPlusComponent,
    RedirectionLinkPipe,
    ModalItemAbbreviationsComponent,
    AddAbbreviationsPipe,
    StripTagsPipe,
    PricingPremiumComponent,
    BaseComponent,
    TopSearchComponent,
    WidgetProgressionComponent,
    SubscriptionCardComponent,
    ImageContentComponent,
    TextContentComponent,
    ParagraphContentComponent,
    BackgroundContentComponent,
    ModalCreateChallengeComponent,
    FriendInviteComponent,
    CountdownPipe,
    ModalChallengeBadgeInfosComponent,
    ModalUserProfileComponent,
    UserProfileComponent,
    ModalListFriendsComponent,
    StudentThumbnailComponent,
    ChallengeDescriptionComponent,
    ChallengeMajorComponent,
    ModalSearchUserComponent,
    DisciplineChallengeComponent,
    ModalStartMessageGroupComponent,
    ChooseFriendsComponent,
    TestimonyCardComponent,
    QuestionContentComponent,
    QuestionImagesComponent,
    QuestionHeaderComponent,
    QuestionCorrectionTopComponent,
    ModalEndExamComponent,
    ImageFallbackDirective,
    CollapsibleElementDirective,
    GradientHiddenDirective,
    ModalCandidateChallengeComponent,
    IntroCandidateChallengeComponent,
    IntroQuestionChallengeComponent,
    QuestionChallengeComponent,
    EndChallengeComponent,
    ModalCorrectionChallengeComponent,
    UserSunrayComponent,
    ModalViewRevisionComponent,
    ModalUpdateVersionComponent,
    ModalPostFeedbackComponent,
    ModalWarningComponent,
    ModalLaunchAncrageRevisionsComponent,
    ModalResetPasswordComponent,
    ModalCreateExamCustomComponent,
    ModalMessageComponent,
    LoaderBookComponent,
    LoaderDotsComponent,
    LoaderHashtagComponent,
    LoaderInfiniteScrollComponent,
    FolderDoneCardComponent,
    ModalUsersInMessageGroupComponent,
    ModalLaunchTrainingCustomComponent,
    ModalLaunchTrainingAncrageComponent,
    ActionButtonsComponent,
    ModalCourseCustomNotesComponent,
    ModalChooseFolderComponent,
    ModalPdfFullscreenComponent,
    PanelDirective,
    BaseDirective,
    ModalQuestionFeedbackComponent,
    ParagraphizePipe,
    NotionsListComponent,
    ModalNewTestimonyComponent,
    ChallengeActualitiesComponent,
    MajorTestimonyCardComponent,
    ArticleDetailComponent,
    BlogCommentComponent,
    ModalRateFolderComponent,
    BlockBodyScrollDirective,
    ItemImageCardComponent,
    RandomOrderPipe,
    VideoCardComponent,
    ModalVideoComponent,
    PricingBlockFeatureComponent,
    ModalEliteComponent,
    SelectWithSearchComponent,
    PricingFeaturesTableSectionComponent,
    PricingFeaturesTableRowComponent,
    LandingTestimoniesComponent,
    LandingAncrageComponent,
    LandingFeedbacksComponent,
    HeaderExamComponent,
    ExamPreviewContentComponent,
    WidgetButtonsHiddenOnTopscrollDirective,
    ModalEndEcosComponent,
    FaqFolderDetailComponent,
    FaqQuestionDiscussionComponent,
    ModalFeedbacksQuestionComponent,
    ExamStudentRankingComponent,
    ExamFacRankingComponent,
    ExamRankingFilterComponent,
    CapitalizeFirstLetterPipe,
    ModalTutoEcosComponent,
    ViewRevisionComponent,
    PodcastCardComponent,
    ModalPodcastComponent,
    ModalJudgeQuestionRelevanceComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: environment.locale },
    StripTagsPipe,
    RedirectionLinkPipe,
    Title,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MomentModule,
    ExamCardComponent,
    TrainingDisciplineCardComponent,
    TrainingItemCardComponent,
    ProgressAncrageTrainingCardComponent,
    ProgressCustomTrainingCardComponent,
    MajorsTestimoniesComponent,
    CopyRestrictedDirective,
    Utf8EncodePipe,
    SafePipe,
    StarsGradeComponent,
    SiteLogoComponent,
    MatDialogModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatRadioModule,
    MatSnackBarModule,
    MatRippleModule,
    MatSelectModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatListModule,
    OvalHeaderCardComponent,
    CourseCardComponent,
    DashboardAncrageCardComponent,
    DashboardDisciplineCardComponent,
    MultirankCardComponent,
    BlogArticleCardComponent,
    NgxUsefulSwiperModule,
    QuestionCandidateFormComponent,
    QuestionCorrectionFormComponent,
    SocialConnexionComponent,
    NotFoundComponent,
    HistogramComponent,
    PdfViewerComponent,
    InfiniteScrollDirective,
    RankingTableComponent,
    AccordionTableDirective,
    RankingPipe,
    RankingFacTableComponent,
    MatBadgeModule,
    MatSidenavModule,
    AddAbbreviationsPipe,
    AngularSvgIconModule,
    StripTagsPipe,
    MatTabsModule,
    PricingPremiumComponent,
    BaseComponent,
    TopSearchComponent,
    WidgetProgressionComponent,
    SubscriptionCardComponent,
    ImageContentComponent,
    TextContentComponent,
    ParagraphContentComponent,
    CountdownPipe,
    UserProfileComponent,
    StudentThumbnailComponent,
    ChallengeDescriptionComponent,
    ChallengeMajorComponent,
    ChooseFriendsComponent,
    TestimonyCardComponent,
    QuestionContentComponent,
    QuestionImagesComponent,
    QuestionHeaderComponent,
    QuestionCorrectionTopComponent,
    ImageFallbackDirective,
    CollapsibleElementDirective,
    GradientHiddenDirective,
    UserSunrayComponent,
    NgxExtendedPdfViewerModule,
    ModalViewRevisionComponent,
    ModalUpdateVersionComponent,
    ModalPostFeedbackComponent,
    ModalWarningComponent,
    ModalLaunchAncrageRevisionsComponent,
    ModalResetPasswordComponent,
    ModalCreateExamCustomComponent,
    ModalMessageComponent,
    MatIconModule,
    LoaderBookComponent,
    LoaderDotsComponent,
    LoaderHashtagComponent,
    LoaderInfiniteScrollComponent,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatStepperModule,
    FolderDoneCardComponent,
    ActionButtonsComponent,
    EditorModule,
    PanelDirective,
    ParagraphizePipe,
    NotionsListComponent,
    ChallengeActualitiesComponent,
    ArticleDetailComponent,
    BlockBodyScrollDirective,
    ItemImageCardComponent,
    RandomOrderPipe,
    VideoCardComponent,
    PricingBlockFeatureComponent,
    NgxMatSelectSearchModule,
    SelectWithSearchComponent,
    LandingTestimoniesComponent,
    LandingAncrageComponent,
    LandingFeedbacksComponent,
    HeaderExamComponent,
    ExamPreviewContentComponent,
    WidgetButtonsHiddenOnTopscrollDirective,
    FaqFolderDetailComponent,
    FaqQuestionDiscussionComponent,
    ExamStudentRankingComponent,
    ExamFacRankingComponent,
    ExamRankingFilterComponent,
    CapitalizeFirstLetterPipe,
    ViewRevisionComponent,
    PodcastCardComponent,
  ],
})
export class SharedModule {}
