import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { QueryCollection } from '../../../shared/models/collection.model';
import {
  QueryData,
  TrainingDisciplineQueryData,
  TrainingItemQueryData,
} from '../../../shared/models/query-data.model';
import { Question } from '../../../shared/models/question.model';
import { TrainingSessionLog } from '../../../shared/models/session-log.model';
import {
  AncrageProgression,
  TrainingCorrection,
  TrainingDiscipline,
  TrainingItem,
  TrainingParamsAnswers,
  TrainingParamsQuestions,
  TrainingStatus,
} from '../../../shared/models/training.model';

import { TrainingEndpoints } from '../../../shared/endpoints/training.endpoints';

@Injectable({
  providedIn: 'root',
})
export class HttpTrainingService {
  constructor(private http: HttpClient) {}

  getTrainingDisciplines(options: TrainingDisciplineQueryData) {
    return this.http
      .get(TrainingEndpoints.trainingDisciplines, {
        params: options as unknown as HttpParams,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((trainingRaw) => {
            return new TrainingDiscipline(trainingRaw);
          });
          return new QueryCollection<TrainingDiscipline>(res);
        })
      );
  }

  getTrainingItems(options: TrainingItemQueryData) {
    return this.http
      .get(TrainingEndpoints.trainingItems, {
        params: options as HttpParams,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((trainingRaw) => {
            return new TrainingItem(trainingRaw);
          });
          return new QueryCollection<TrainingItem>(res);
        })
      );
  }

  getQuestion(options: TrainingParamsQuestions) {
    return this.http
      .get(TrainingEndpoints.continuousQuestion, {
        params: options as HttpParams,
      })
      .pipe(
        map((questionRaw: any) => {
          const question = new Question(questionRaw);
          return question;
        })
      );
  }

  getTrainingStatus(options: TrainingParamsQuestions) {
    return this.http
      .get(TrainingEndpoints.trainingStatus, {
        params: options as HttpParams,
      })
      .pipe(
        map((statusRaw: any) => {
          const status = new TrainingStatus(statusRaw.candidateQuestion);
          return status;
        })
      );
  }

  getAncrageQuestion(options: TrainingParamsQuestions) {
    return this.http
      .get(TrainingEndpoints.ancrageQuestion, {
        params: options as HttpParams,
      })
      .pipe(
        map((questionRaw: any) => {
          const question = new Question(questionRaw);
          return question;
        })
      );
  }

  getQuestionById(questionId: number) {
    return this.http.get(TrainingEndpoints.continuousQuestionById(questionId)).pipe(
      map((questionRaw: any) => {
        const question = new Question(questionRaw);
        return question;
      })
    );
  }

  postAnswer(options: TrainingParamsAnswers) {
    return this.http.post(TrainingEndpoints.continuousQuestionById(options.id), options).pipe(
      map((trainingCorrectionRaw: any) => {
        const trainingCorrection = new TrainingCorrection(trainingCorrectionRaw);
        return trainingCorrection;
      })
    );
  }

  postAncrageAnswer(options: TrainingParamsAnswers) {
    return this.http.post(TrainingEndpoints.ancrageQuestionById(options.id), options).pipe(
      map((trainingCorrectionRaw: any) => {
        const trainingCorrection = new TrainingCorrection(trainingCorrectionRaw);
        return trainingCorrection;
      })
    );
  }

  stopTrainingSession(options: TrainingParamsQuestions) {
    return this.http.get(TrainingEndpoints.stopTrainingSession, {
      params: options as HttpParams,
    });
  }

  deleteQuestion(id: number) {
    return this.http.delete(TrainingEndpoints.continuousQuestionById(id));
  }

  deleteQuestionAncrage(id: number) {
    return this.http.get(TrainingEndpoints.deleteQuestionAncrage(id));
  }

  deleteRevision(id: number, params: TrainingParamsQuestions) {
    return this.http.patch(TrainingEndpoints.deleteRevision(id), params);
  }

  getActivity(options: QueryData) {
    return this.http
      .get(TrainingEndpoints.getActivity, {
        params: options as HttpParams,
      })
      .pipe(
        map((res: any) => {
          res.data = res.data.map((trainingLogRaw) => {
            return new TrainingSessionLog(trainingLogRaw);
          });
          return new QueryCollection<TrainingSessionLog>(res);
        })
      );
  }

  getAncrageProgression() {
    return this.http.get(TrainingEndpoints.getAncrageProgression).pipe(
      map((progress: any) => {
        return new AncrageProgression(progress);
      })
    );
  }

  reset(type: 'disciplines' | 'items', id: number) {
    return this.http.delete(TrainingEndpoints.resetProgression(type, id));
  }

  resetAncrage() {
    return this.http.get(TrainingEndpoints.resetAncrage);
  }

  judgeRelevance(id: number, judgedAsR2CRelevant: number, judgedAsCorrectionRelevant: number) {
    return this.http.post(TrainingEndpoints.judgeRelevance(id), {
      judgedAsR2CRelevant,
      judgedAsCorrectionRelevant,
    });
  }

  addCommentRelevance(id: number, comment: string) {
    return this.http.post(TrainingEndpoints.addCommentRelevance, { comment, question: id });
  }
}
