<div
  class="block-scrollable-list {{ scrollableListHeight }} sm:h-[270px]"
  id="friendsList"
  appInfiniteScroll
  (needToScroll)="onScroll()"
  [scrollContainer]="'friendsList'"
  [scrollContent]="'scrollableContent'"
>
  <div class="scrollable_content" id="scrollableContent">
    <ng-container
      *ngIf="
        !selectedListDisplayed.length && !friendsList.length && !infiniteScrollDirective?.showLoader
      "
    >
      {{ 'consensus.pas_resultats' | translate }}
    </ng-container>

    <div class="list-grid">
      <app-friend-invite
        *ngFor="let user of selectedListDisplayed"
        [user]="user"
        (click)="toggleUserSelected(user)"
        [selected]="isUserSelected(user)"
      ></app-friend-invite>

      <ng-container *ngFor="let user of friendsList">
        <app-friend-invite
          *ngIf="!isUserAlreadyDisplayed(user)"
          [user]="user"
          (click)="toggleUserSelected(user)"
          [selected]="isUserSelected(user)"
        ></app-friend-invite>
      </ng-container>
    </div>

    <div class="spinner_list_scroll_container" *ngIf="infiniteScrollDirective?.showLoader">
      <app-loader-dots></app-loader-dots>
    </div>
  </div>
</div>

<div class="block--friends-list-search mt-8">
  <div class="row-form-nnki -nowrap">
    <mat-form-field [floatLabel]="'auto'" class="form-field-nnki width100">
      <mat-label>{{ 'challenge.rechercher' | translate }}</mat-label>
      <input matInput type="text" [formControl]="friendSearchControl" />
    </mat-form-field>
    <button
      matRipple
      [matRippleCentered]="false"
      [matRippleUnbounded]="true"
      [matRippleRadius]="50"
      class="btn-nnki btn-nnki-primary btn-nnki-icon"
    >
      <svg-icon
        src="assets/images/svg-icons/icon-search-bold.svg"
        [svgStyle]="{ 'width.px': '20' }"
      ></svg-icon>
    </button>
  </div>
</div>

<button
  matRipple
  [matRippleCentered]="false"
  [matRippleUnbounded]="true"
  [matRippleRadius]="50"
  type="button"
  class="btn-nnki btn-nnki-primary btn-nnki-icon-left btn-nnki-uppercase btn-nnki-heavy ml-auto mt-8 bg-facebook text-11 sm:text-12"
  (click)="facebookImport()"
  *ngIf="!isImporting"
>
  <svg-icon
    src="assets/images/svg-icons/icon-facebook.svg"
    [svgStyle]="{ 'width.px': '22' }"
  ></svg-icon>
  {{ 'settings.facebook_import' | translate }}
</button>

<div class="spinner_100_container" *ngIf="isImporting">
  <app-loader-dots></app-loader-dots>
</div>
