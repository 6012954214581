import { Discipline } from './discipline.model';
import { parseIntMember } from '../utils/parse-functions';
import { environment } from '../../../environments/environment';

export class Item {
  id: number;
  title: string;
  number: number;
  display?: string;
  value?: string;

  disciplines?: Discipline[];

  constructor(input: any) {
    parseIntMember(input, 'id');
    parseIntMember(input, 'number');

    Object.assign(this, input);
    if (this.id) this.value = this.id.toString();
    this.display = this.number + ' - ' + this.title;

    if (this.disciplines) {
      this.disciplines = this.disciplines.map((discipline) => {
        return new Discipline(discipline);
      });
    } else {
      this.disciplines = [];
    }
  }

  get isSDD() {
    return (
      this.disciplines.find((discipline) => discipline.id === 42) &&
      environment.defaultLanguage === 'fr'
    );
  }
}

export class ItemImageList extends Item {
  nbImages: number;
  image: ItemImage;

  constructor(input: any) {
    parseIntMember(input, 'nbImages');

    super(input);

    if (this.image) {
      this.image = new ItemImage(this.image);
    }
  }
}

export class ItemImage {
  id: number;
  url: string;

  constructor(input: any) {
    parseIntMember(input, 'id');

    Object.assign(this, input);
  }

  get imageUrl() {
    return this.url;
  }
}
