import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { debounceTime, finalize, takeUntil } from 'rxjs/operators';

import { HttpSocialService } from '../../../core/services/http/http-social.service';
import { HttpUserService } from './../../../core/services/http/http-user.service';
import { UserService } from './../../../core/services/user.service';

import { BaseComponent } from '../../components/inherited/base/base.component';

import { EcniUser } from '../../models/ecni-user.model';

import { InfiniteScrollDirective } from '../../directives/infinite-scroll.directive';

@Component({
  selector: 'app-choose-friends',
  templateUrl: './choose-friends.component.html',
  styleUrls: ['./choose-friends.component.scss'],
})
export class ChooseFriendsComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() usersSelected: EcniUser[] = [];
  @Input() scrollableListHeight: string;
  @Output() selectedListChanged = new EventEmitter<EcniUser[]>();

  friendSearchControl = new UntypedFormControl();

  isImporting = false;

  friendsList: EcniUser[] = [];

  selectedListDisplayed: EcniUser[] = [];
  selectedList: EcniUser[] = [];

  @ViewChild(InfiniteScrollDirective) infiniteScrollDirective: InfiniteScrollDirective;

  constructor(
    private httpSocialService: HttpSocialService,
    private userService: UserService,
    private httpUserService: HttpUserService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.usersSelected) {
      this.usersSelected.forEach((user) => {
        this.toggleUserSelected(user);
      });

      this.updateSelectedListDisplayed();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getUsersByFriends();
    });

    this.friendSearchControl.valueChanges
      .pipe(takeUntil(this.alive$), debounceTime(500))
      .subscribe((newValue) => {
        this.friendsList = [];
        this.infiniteScrollDirective.resetScrollProperties();

        this.updateSelectedListDisplayed();

        if (newValue.length) {
          this.getUsersBySearch();
        } else {
          this.getUsersByFriends();
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getUsersByFriends() {
    if (this.infiniteScrollDirective?.canScroll()) {
      this.infiniteScrollDirective.initiateScroll();

      this.httpSocialService
        .getFollowed(this.userService.loggedUserValue.id, {
          sortBy: 'username',
          sortByOrder: 'ASC',
          page: this.infiniteScrollDirective.currentPage,
          perPage: 20,
        })
        .subscribe(
          (res) => {
            this.friendsList = this.friendsList.concat(res.data);
            this.infiniteScrollDirective.endScroll(res.metadata);
          },
          () => {
            this.infiniteScrollDirective.scrollHasErrored();
          }
        );
    }
  }

  getUsersBySearch() {
    if (this.infiniteScrollDirective?.canScroll()) {
      this.infiniteScrollDirective.initiateScroll();

      this.httpUserService
        .search({
          search: this.friendSearchControl.value,
          perPage: 20,
          page: this.infiniteScrollDirective.currentPage,
          sortBy: 'username',
          sortByOrder: 'ASC',
        })
        .subscribe(
          (res) => {
            res.data = res.data.filter((user) => {
              return user.id !== this.userService.loggedUserValue.id;
            });
            this.friendsList = this.friendsList.concat(res.data);
            this.infiniteScrollDirective.endScroll(res.metadata);
          },
          () => {
            this.infiniteScrollDirective.scrollHasErrored();
          }
        );
    }
  }

  onScroll() {
    if (this.friendSearchControl.value && this.friendSearchControl.value.length) {
      this.getUsersBySearch();
    } else {
      this.getUsersByFriends();
    }
  }

  isUserSelected(user: EcniUser): boolean {
    return !!this.selectedList.find((student) => {
      return student.id === user.id;
    });
  }

  toggleUserSelected(user: EcniUser) {
    if (this.isUserSelected(user)) {
      this.selectedList = this.selectedList.filter((student) => {
        return student.id !== user.id;
      });
    } else {
      this.selectedList.push(user);
    }
    this.selectedListChanged.emit(this.selectedList);
  }

  isUserAlreadyDisplayed(user: EcniUser): boolean {
    return !!this.selectedListDisplayed.find((student) => {
      return student.id === user.id;
    });
  }

  updateSelectedListDisplayed() {
    this.selectedListDisplayed = [];
    this.selectedList.forEach((user) => {
      this.selectedListDisplayed.push(user);
    });
  }

  facebookImport() {
    this.isImporting = true;
    this.userService
      .facebookImport()
      .pipe(
        finalize(() => {
          this.isImporting = false;
        })
      )
      .subscribe(() => {
        if (!this.friendSearchControl.value || !this.friendSearchControl.value.length) {
          this.friendsList = [];
          this.infiniteScrollDirective.resetScrollProperties();

          this.updateSelectedListDisplayed();
          this.getUsersByFriends();
        }
      });
  }
}
