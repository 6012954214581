import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { timer } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';

import { DisciplinesService } from '../../../../core/services/disciplines.service';
import { SoundService } from '../../../../core/services/sound.service';
import { UserService } from './../../../../core/services/user.service';

import { ExamCustom } from '../../../models/exam-custom.model';
import { Answer } from './../../../models/answer.model';
import { AnswersCorrection, Question } from './../../../models/question.model';

@Component({
  selector: 'app-question-challenge',
  templateUrl: './question-challenge.component.html',
  styleUrls: ['./question-challenge.component.scss'],
})
export class QuestionChallengeComponent implements OnInit {
  @Input() exam: ExamCustom;
  @Input() currentQuestion: Question;
  @Input() progressBarPercentage = 0;
  @Input() timeRemaining = 0;
  @Input() validatingAnswer = false;

  @Output() questionValidatedEmitter = new EventEmitter();

  isCorrection = false;
  showCorrection = false;
  answersCorrection: AnswersCorrection;

  animationState = {
    questionTop: false,
    questionContent: false,
    questionText: false,
    questionNumber: false,
    questionAnswers: false,
    questionQroc: false,
    questionZap: false,
    questionButton: false,
  };

  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasContainer') canvasContainer: ElementRef<HTMLDivElement>;
  @ViewChild('imageZAP') imageZAP: ElementRef<HTMLImageElement>;

  canvasContext: CanvasRenderingContext2D;

  constructor(
    private soundService: SoundService,
    public userService: UserService,
    public disciplinesService: DisciplinesService
  ) {}

  ngOnInit(): void {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeCanvas();
    if (this.isCorrection) {
      this.drawCorrection();
    } else {
      this.drawPoint();
    }
  }

  checkAnswer(answer: Answer) {
    if (!this.isCorrection) {
      answer.userChecked = !answer.userChecked;

      if (this.currentQuestion.type === 'question.one') {
        this.currentQuestion.answers.forEach((answerToUncheck) => {
          if (answer.id !== answerToUncheck.id) {
            answerToUncheck.userChecked = false;
          }
        });
      }
    }
  }

  validateAnswer() {
    if (!this.isCorrection) {
      this.questionValidatedEmitter.emit();
    }
    // nnkitodo [zap] affichage des zones à la correction et couleur du point cliqué
  }

  get ceiledCountdown() {
    return Math.ceil(this.timeRemaining / 60);
  }

  launchAnimationsEntry() {
    timer(0)
      .pipe(
        concatMap((res) => {
          this.animationState.questionTop = true;
          this.animationState.questionContent = true;
          return timer(300);
        }),
        concatMap((res) => {
          this.animationState.questionNumber = true;
          return timer(100);
        }),
        concatMap((res) => {
          this.animationState.questionText = true;
          return timer(500);
        }),
        concatMap((res) => {
          this.animationState.questionAnswers = true;
          this.animationState.questionQroc = true;
          this.animationState.questionZap = true;
          return timer(100);
        }),
        concatMap((res) => {
          this.animationState.questionButton = true;
          return timer(100);
        }),
        first()
      )
      .subscribe();
  }

  launchAnimationsAnswer(answersCorrection: AnswersCorrection) {
    this.isCorrection = true;
    this.answersCorrection = answersCorrection;

    if (this.soundService.canPlayChallengeSounds()) {
      if (answersCorrection.answerIsCorrect) {
        this.soundService.playSound('challenge-right-answer');
      } else {
        this.soundService.playSound('challenge-wrong-answer');
      }
    }

    if (this.currentQuestion.type === 'question.match') {
      if (answersCorrection) {
        this.currentQuestion.matchIsCorrect = answersCorrection.answerIsCorrect;
      }
    } else if (this.currentQuestion.type === 'question.imageRecognition') {
      this.currentQuestion.ZAPIsCorrect = answersCorrection.answerIsCorrect;
      this.currentQuestion.answers = answersCorrection.correctAnswers;
      this.drawCorrection();
    } else {
      if (answersCorrection) {
        if (answersCorrection.answerIsCorrect) {
          this.currentQuestion.answers.map((answer) => {
            if (answer.userChecked) {
              answer.type = 'answer.win';
            } else {
              answer.type = 'answer.fail';
            }
          });
        } else {
          this.currentQuestion.answers.find((answer) => {
            const isCorrect = answersCorrection.correctAnswers.find((correctAnswer) => {
              return correctAnswer.id === answer.id;
            });
            if (isCorrect) {
              answer.type = 'answer.win';
            } else {
              answer.type = 'answer.fail';
            }
          });
        }
      }
    }

    this.showCorrection = true;
  }

  launchAnimationsExit() {
    return timer(0).pipe(
      concatMap((res) => {
        this.animationState.questionButton = false;
        return timer(100);
      }),
      concatMap((res) => {
        this.animationState.questionAnswers = false;
        this.animationState.questionQroc = false;
        this.animationState.questionZap = false;
        return timer(100);
      }),
      concatMap((res) => {
        this.animationState.questionText = false;
        return timer(100);
      }),
      concatMap((res) => {
        this.animationState.questionNumber = false;
        return timer(100);
      }),
      concatMap((res) => {
        this.animationState.questionTop = false;
        this.animationState.questionContent = false;
        return timer(100);
      }),
      first()
    );
  }

  answerIsCorrect(answer) {
    return answer.type === 'answer.win' || answer.type === 'answer.need';
  }

  initCanvas() {
    if (this.canvas && this.canvasContainer && this.canvas.nativeElement.getContext) {
      this.canvasContext = this.canvas.nativeElement.getContext('2d');
      this.resizeCanvas();
      this.drawPoint();
    }
  }

  resizeCanvas() {
    if (this.canvas && this.canvasContainer && this.canvas.nativeElement.getContext) {
      this.canvas.nativeElement.width = this.canvasContainer.nativeElement.clientWidth;
      this.canvas.nativeElement.height = this.canvasContainer.nativeElement.clientHeight;
    }
  }

  selectPoint(event: any) {
    const clickX = event.offsetX;
    const clickY = event.offsetY;

    const displayedImageWidth = this.imageZAP.nativeElement.clientWidth;
    const displayedImageHeight = this.imageZAP.nativeElement.clientHeight;
    const naturalImageWidth = this.imageZAP.nativeElement.naturalWidth;
    const naturalImageHeight = this.imageZAP.nativeElement.naturalHeight;

    const coordsImagePointX = (naturalImageWidth * clickX) / displayedImageWidth;
    const coordsImagePointY =
      naturalImageHeight - (naturalImageHeight * clickY) / displayedImageHeight;

    this.currentQuestion.userAnswerZAP = {
      x: Math.round(coordsImagePointX),
      y: Math.round(coordsImagePointY),
    };

    this.drawPoint();
  }

  drawPoint() {
    if (this.canvasContext && this.currentQuestion.userAnswerZAP) {
      const displayedImageWidth = this.imageZAP.nativeElement.clientWidth;
      const displayedImageHeight = this.imageZAP.nativeElement.clientHeight;
      const naturalImageWidth = this.imageZAP.nativeElement.naturalWidth;
      const naturalImageHeight = this.imageZAP.nativeElement.naturalHeight;

      this.canvasContext.clearRect(
        0,
        0,
        this.canvas.nativeElement.clientWidth,
        this.canvas.nativeElement.clientHeight
      );

      this.canvasContext.fillStyle = '#2eff00';

      const pointX =
        (this.currentQuestion.userAnswerZAP.x * displayedImageWidth) / naturalImageWidth;
      const pointY =
        ((naturalImageHeight - this.currentQuestion.userAnswerZAP.y) * displayedImageHeight) /
        naturalImageHeight;
      this.canvasContext.fillRect(pointX - 4, pointY - 4, 8, 8);
    }
  }

  drawCorrection() {
    if (this.canvasContext && this.currentQuestion.userAnswerZAP) {
      const displayedImageWidth = this.imageZAP.nativeElement.clientWidth;
      const displayedImageHeight = this.imageZAP.nativeElement.clientHeight;
      const naturalImageWidth = this.imageZAP.nativeElement.naturalWidth;
      const naturalImageHeight = this.imageZAP.nativeElement.naturalHeight;

      this.canvasContext.clearRect(
        0,
        0,
        this.canvas.nativeElement.clientWidth,
        this.canvas.nativeElement.clientHeight
      );

      let pointClicked = null;

      if (this.currentQuestion.candidateAnswers.length) {
        pointClicked = this.currentQuestion.candidateAnswers[0].coordinates;
      } else if (this.currentQuestion.userAnswerZAP) {
        pointClicked = this.currentQuestion.userAnswerZAP;
      }

      if (pointClicked) {
        this.canvasContext.fillStyle = this.currentQuestion.ZAPIsCorrect ? '#2eff00' : '#fc4747';

        const pointX = (pointClicked.x * displayedImageWidth) / naturalImageWidth;
        const pointY =
          ((naturalImageHeight - pointClicked.y) * displayedImageHeight) / naturalImageHeight;
        this.canvasContext.fillRect(pointX - 4, pointY - 4, 8, 8);
      }

      this.canvasContext.fillStyle = this.currentQuestion.ZAPIsCorrect
        ? 'rgba(46, 255, 0, 0.2)'
        : 'rgba(252, 71, 71, 0.2)';

      if (this.currentQuestion.answers.length) {
        if (this.currentQuestion.answers[0].zones?.length) {
          this.currentQuestion.answers[0].zones.forEach((zone) => {
            this.canvasContext.beginPath();
            zone.forEach((point, idx) => {
              const pointX = (point.x * displayedImageWidth) / naturalImageWidth;
              const pointY =
                ((naturalImageHeight - point.y) * displayedImageHeight) / naturalImageHeight;

              if (idx === 0) {
                this.canvasContext.moveTo(pointX, pointY);
              } else {
                this.canvasContext.lineTo(pointX, pointY);
              }
            });

            this.canvasContext.closePath();
            this.canvasContext.fill();
          });
        }
      }
    }
  }
}
