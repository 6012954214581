import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { finalize, takeUntil } from 'rxjs/operators';

import { HttpChallengeService } from '../../../core/services/http/http-challenge.service';
import { HttpSocialService } from '../../../core/services/http/http-social.service';
import { IsApplicationService } from '../../../core/services/is-application.service';
import { SanitizerService } from '../../../core/services/sanitizer.service';
import { UserService } from '../../../core/services/user.service';
import { ColorService } from './../../../core/services/color.service';
import { EcniTweetsService } from './../../../core/services/ecni-tweets.service';
import { HttpUserService } from './../../../core/services/http/http-user.service';
import { LanguageService } from './../../../core/services/language.service';
import { MessagingService } from './../../../core/services/messaging.service';
import { ModalService } from './../../../core/services/modal.service';

import { BaseComponent } from '../../components/inherited/base/base.component';

import { UserResult } from '../../models/auth.model';
import {
  ChallengeRanking,
  ChallengeRankingLevel,
  ChallengeVictories,
} from '../../models/challenge.model';
import { EcniUser } from '../../models/ecni-user.model';
import { ModalListFriendsData } from '../../models/modal-data';
import { SocialStats } from '../../models/social.model';

import { ModalChallengeBadgeInfosComponent } from '../../modals/modal-challenge-badge-infos/modal-challenge-badge-infos.component';
import { ModalListFriendsComponent } from '../../modals/modal-list-friends/modal-list-friends.component';

declare var ProgressBar: any;

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() userId: number;
  @Input() inModale = false;

  user: EcniUser;

  userRanking: ChallengeRanking;
  rankingLevels: ChallengeRankingLevel[];
  socialStats: SocialStats;
  waitingRequests: number;
  userData: UserResult;
  userVictories: ChallengeVictories;

  svgProgress;
  svgSuccess;
  now = new Date().getTime();

  isFacebookImporting = false;

  constructor(
    public sanitizerService: SanitizerService,
    public userService: UserService,
    private httpChallengeService: HttpChallengeService,
    public isApplicationService: IsApplicationService,
    private matDialog: MatDialog,
    public ecniTweetsService: EcniTweetsService,
    private messagingService: MessagingService,
    public modalService: ModalService,
    private httpSocialService: HttpSocialService,
    private colorService: ColorService,
    public languageService: LanguageService,
    private httpUserService: HttpUserService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.userId === this.userService.loggedUserValue.id) {
      this.userService.loggedUser$.pipe(takeUntil(this.alive$)).subscribe((res) => {
        this.user = res;
      });
    }

    this.httpChallengeService.getUserRanking('overall', this.userId).subscribe((res) => {
      this.userRanking = res;
    });

    this.httpSocialService.getStats(this.userId).subscribe((res) => {
      this.socialStats = res;

      this.ecniTweetsService.myUserFollowedChangedEmitter
        .pipe(takeUntil(this.alive$))
        .subscribe((val) => {
          if (this.userId === this.userService.loggedUserValue.id) {
            this.socialStats.followings += val;
          }
        });

      this.ecniTweetsService.myUserFollowersChangedEmitter
        .pipe(takeUntil(this.alive$))
        .subscribe((val) => {
          if (this.userId === this.userService.loggedUserValue.id) {
            this.socialStats.followers += val;
          }
        });
    });

    if (this.userId === this.userService.loggedUserValue.id) {
      this.httpSocialService
        .getFollowersRequests({
          perPage: 1,
        })
        .subscribe((res) => {
          this.waitingRequests = res.metadata.totalItems;

          this.ecniTweetsService.myUserWaitingChangedEmitter
            .pipe(takeUntil(this.alive$))
            .subscribe((val) => {
              this.waitingRequests += val;
            });
        });
    }

    this.httpChallengeService.getUserRankingLevels(this.userId).subscribe((res) => {
      this.rankingLevels = res;
    });

    this.httpUserService.getUser(this.userId).subscribe((res) => {
      setTimeout(() => {
        this.userData = res;

        if (this.userId !== this.userService.loggedUserValue.id) {
          this.user = this.userData.user;
        }

        if (document.getElementById('svg_progress-' + this.now)) {
          this.svgProgress.animate(this.userData.participation);
        }

        if (document.getElementById('svg_success-' + this.now)) {
          this.svgSuccess.animate(this.userData.success);
        }
      });
    });

    this.httpChallengeService.getUserVictories(this.userId).subscribe((res) => {
      this.userVictories = res;
    });
  }

  ngAfterViewInit(): void {
    this.initCharts();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  initCharts() {
    setTimeout(() => {
      if (
        ProgressBar &&
        document.getElementById('svg_progress-' + this.now) &&
        document.getElementById('svg_success-' + this.now)
      ) {
        this.svgProgress = new ProgressBar.Circle('#svg_progress-' + this.now, {
          color: this.colorService.getAppPurpleFour(),
          trailColor: this.colorService.getAppPurpleFive(),
          strokeWidth: 15,
        });

        this.svgSuccess = new ProgressBar.Circle('#svg_success-' + this.now, {
          color: this.colorService.getAppGreenThree(),
          trailColor: this.colorService.getGreenFive(),
          strokeWidth: 15,
        });
      }
    });
  }

  openModalChallengeBadgeInfos() {
    this.matDialog.open(ModalChallengeBadgeInfosComponent, {
      maxWidth: '100vw',
    });
  }

  openModalListFriends(type: 'followings' | 'followers' | 'waitingRequest') {
    const modalData: ModalListFriendsData = {
      type,
      userId: this.userId,
    };

    this.matDialog.open(ModalListFriendsComponent, {
      maxWidth: '100vw',
      data: modalData,
    });
  }

  facebookImport() {
    this.isFacebookImporting = true;
    this.userService
      .facebookImport()
      .pipe(
        finalize(() => {
          this.isFacebookImporting = false;
        })
      )
      .subscribe((nbImported) => {
        this.ecniTweetsService.emitMyUserFollowedChanged(nbImported);
      });
  }

  createTemporaryMessageGroup(users: EcniUser[]) {
    this.messagingService.createTemporaryMessageGroup(users);
    if (this.inModale) {
      this.matDialog.closeAll();
    }
  }
}
