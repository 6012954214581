import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { FacebookLogin } from '@capacitor-community/facebook-login';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { TranslateService } from '@ngx-translate/core';
// import * as Sentry from '@sentry/browser';
import { ToastrService } from 'ngx-toastr';
import { of, throwError, BehaviorSubject, Observable } from 'rxjs';
import { catchError, concatMap, filter, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { LoginResult, PatchPayload, RegisterPayload } from '../../shared/models/auth.model';
import { EcniUser } from '../../shared/models/ecni-user.model';
import { ModalWarningData } from '../../shared/models/modal-data';

import { RedirectionLinkPipe } from '../../shared/pipes/redirection-link.pipe';

import { ModalWarningComponent } from '../../shared/modals/modal-warning/modal-warning.component';
import { FacebookToEcniService } from './facebook-to-ecni.service';
import { HttpSocialService } from './http/http-social.service';
import { HttpUserService } from './http/http-user.service';
import { IsApplicationService } from './is-application.service';
import { LanguageService } from './language.service';

import { LocaleKeys } from '../../shared/utils/locale-keys';
import { DateFormatPipe } from 'ngx-moment';
import { screenWidthConstants } from '../../shared/utils/screen-sizes';

declare var window: any;

export let LOGIN_STATE = {
  ATTEMPT_TO_RECOVER: 'ATTEMPT_TO_RECOVER',
  IDLE: 'IDLE',
  LOGGED: 'LOGGED',
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  loggedUser$ = new BehaviorSubject<EcniUser>(null);
  loginState$ = new BehaviorSubject<string>(LOGIN_STATE.ATTEMPT_TO_RECOVER);

  accessToken: string;
  refreshToken: string;

  constructor(
    protected router: Router,
    private facebookToEcniService: FacebookToEcniService,
    private httpUserService: HttpUserService,
    private location: Location,
    private isApplicationService: IsApplicationService,
    private translate: TranslateService,
    private httpSocialService: HttpSocialService,
    private matDialog: MatDialog,
    private toastr: ToastrService,
    private languageService: LanguageService,
    private redirectionLinkPipe: RedirectionLinkPipe,
    private dateFormatPipe: DateFormatPipe
  ) {
    this.initSetScopeSettings();
  }

  login(username: string, password: string) {
    this.cleanAuthLocalStorage();

    return this.httpUserService.login(username, password).pipe(
      tap((res) => {
        this.setSession(res);
      }),
      concatMap((res) => {
        return this.loadUserProfile(true);
      }),
      map((resUserProfile) => {
        if (resUserProfile) {
          this.userIsLogged();
          return resUserProfile;
        } else {
          throw new Error('Api data is not valid');
        }
      })
    );
  }

  socialLogin(loginRequest: Observable<LoginResult>) {
    this.cleanAuthLocalStorage();

    return loginRequest.pipe(
      tap((res) => {
        this.setSession(res);
      }),
      concatMap((res) => {
        return this.loadUserProfile(true);
      }),
      map((resUserProfile) => {
        if (resUserProfile) {
          this.userIsLogged();
          return resUserProfile;
        } else {
          throw new Error('Api data is not valid');
        }
      })
    );
  }

  googleLogin(googleAuth: any) {
    this.cleanAuthLocalStorage();

    return this.httpUserService.googleLogin(googleAuth).pipe(
      tap((res) => {
        this.setSession(res);
      }),
      concatMap((res) => {
        return this.loadUserProfile(true);
      }),
      map((resUserProfile) => {
        if (resUserProfile) {
          this.userIsLogged();
          return resUserProfile;
        } else {
          throw new Error('Api data is not valid');
        }
      })
    );
  }

  navigateAfterLogin() {
    if (sessionStorage.getItem(LocaleKeys.navigationBeforeLogin)) {
      const url = sessionStorage.getItem(LocaleKeys.navigationBeforeLogin);
      const redirect = this.redirectionLinkPipe.transform(url);

      this.router.navigate(redirect.commands, redirect.extras);
      sessionStorage.removeItem(LocaleKeys.navigationBeforeLogin);
    } else {
      this.router.navigate(['app/home']);
    }
  }

  logout() {
    this.router.navigate(['/user/login']);

    this.cleanAuthLocalStorage();

    this.loginState$.next(LOGIN_STATE.IDLE);
    this.loggedUser$.next(null);

    this.accessToken = null;
    this.refreshToken = null;

    (window as any).Intercom('shutdown');
    this.bootIntercomSession();

    this.isApplicationService.reenablePremium();

    if (this.isApplicationService.isApplication()) {
      FacebookLogin.getCurrentAccessToken().then((resStatus) => {
        if (resStatus.accessToken) {
          FacebookLogin.logout();
        }
      });

      GoogleAuth.initialize().then(() => {
        GoogleAuth.signOut();
      });
    } else {
      this.facebookToEcniService.initialized().subscribe(() => {
        FB.getLoginStatus((resStatus) => {
          if (resStatus.status && resStatus.status === 'connected') {
            FB.logout(() => {});
            document.cookie =
              'fblo_' + environment.fb_id + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            window.location.reload();
          }
        });
      });
    }
  }

  userIsLogged() {
    this.loginState$.next(LOGIN_STATE.LOGGED);

    if (this.isApplicationService.isAppIos() && !this.isPremiumPlus()) {
      this.isApplicationService.updateIsIosPremiumEnabled();
    }
  }

  register(registerCredentials: RegisterPayload) {
    return this.httpUserService.register(registerCredentials).pipe(
      map((res) => {
        if (!res || !res.message) {
          throw new Error('Api data is not valid');
        } else {
          return res;
        }
      })
    );
  }

  sendResetEmail(email: string) {
    return this.httpUserService.sendResetEmail(email).pipe(
      map((res) => {
        if (!res || !res.success) {
          if (res.error === 'EMAIL_NOT_FOUND') {
            return {
              error: this.translate.instant('login.email_not_found'),
            };
          } else if (res.error === 'PASSWORD_REQUEST_NON_EXPIRED') {
            return {
              error: this.translate.instant('login.password_2h'),
            };
          } else {
            throw new Error();
          }
        } else {
          return res;
        }
      })
    );
  }

  confirmUser(confirmToken: string) {
    return this.httpUserService.confirmUser(confirmToken).pipe(
      tap((res) => {
        this.setSession(res);
      }),
      concatMap(() => {
        return this.loadUserProfile(true);
      }),
      map((resUserProfile) => {
        if (resUserProfile) {
          this.userIsLogged();
          return resUserProfile;
        } else {
          throw new Error('Api data is not valid');
        }
      })
    );
  }

  patchUser(patchCredentials: PatchPayload) {
    return this.httpUserService.patchUser(patchCredentials).pipe(
      concatMap((res) => {
        return this.loadUserProfile(false);
      }),
      map((resUserProfile) => {
        if (resUserProfile) {
          return resUserProfile;
        } else {
          throw new Error('Api data is not valid');
        }
      })
    );
  }

  changeEmail(email: string) {
    const patchCredentials: PatchPayload = {
      email,
    };
    return this.httpUserService.patchUser(patchCredentials).pipe(
      concatMap((res) => {
        return this.loadUserProfile(false);
      }),
      map((resUserProfile) => {
        if (resUserProfile) {
          return resUserProfile;
        } else {
          throw new Error('Api data is not valid');
        }
      })
    );
  }

  recoverSession() {
    if (this.variablesInSessionExists()) {
      return this.recoverStorageVariables().pipe(
        tap((res) => {
          this.userIsLogged();
        }),
        catchError((err) => {
          this.saveNavBeforeLogin();
          this.logout();
          return throwError(err);
        })
      );
    } else {
      this.saveNavBeforeLogin();
      this.bootIntercomSession();
      this.loginState$.next(LOGIN_STATE.IDLE);
      return of(true);
    }
  }

  saveNavBeforeLogin(savedUrl?: string) {
    let url: string;
    if (savedUrl) {
      url = savedUrl;
    } else {
      url = this.location.path(true);
    }

    if (url.indexOf('/app') !== -1) {
      sessionStorage.setItem(LocaleKeys.navigationBeforeLogin, url);
    }
  }

  private setSession(loginResult: LoginResult) {
    this.accessToken = loginResult.access_token;
    localStorage.setItem(LocaleKeys.tokenId, this.accessToken);

    this.refreshToken = loginResult.refresh_token;
    localStorage.setItem(LocaleKeys.refreshTokenId, this.refreshToken);

    const expiresAt = (loginResult.expires_in * 1000 + new Date().getTime()).toString();
    localStorage.setItem(LocaleKeys.tokenExpiresAt, expiresAt);
  }

  variablesInSessionExists() {
    return (
      localStorage.getItem(LocaleKeys.tokenExpiresAt) &&
      localStorage.getItem(LocaleKeys.tokenId) &&
      localStorage.getItem(LocaleKeys.refreshTokenId)
    );
  }

  isStillLoggedIn() {
    const expiresAt = parseInt(localStorage.getItem(LocaleKeys.tokenExpiresAt), 10);

    if (!expiresAt) {
      return false;
    } else {
      return Date.now() < expiresAt && this.variablesInSessionExists();
    }
  }

  private recoverStorageVariables() {
    this.accessToken = localStorage.getItem(LocaleKeys.tokenId);
    this.refreshToken = localStorage.getItem(LocaleKeys.refreshTokenId);
    return this.loadUserProfile(true).pipe(
      map((res) => {
        if (res) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  refreshAuthToken() {
    return this.httpUserService.refreshAuthToken(this.refreshToken).pipe(
      tap((authResult) => {
        this.setSession(authResult);
      }),
      catchError((error) => {
        this.logout();
        return throwError(error);
      })
    );
  }

  updateUser() {
    this.loadUserProfile(false).subscribe();
  }

  private loadUserProfile(newLogin: boolean) {
    return this.httpUserService.getUserProfile().pipe(
      map((res) => {
        if (res && res.user) {
          if (newLogin) {
            this.bootIntercomSession(res.user.id);
          }
          this.setUserProfile(res.user);
          return res.user;
        } else {
          throw new Error('Api data is not valid');
        }
      })
    );
  }

  private setUserProfile(userProfile: EcniUser) {
    this.loggedUser$.next(userProfile);
    localStorage.setItem(LocaleKeys.authProfile, JSON.stringify(userProfile));
  }

  private cleanAuthLocalStorage() {
    this.accessToken = null;
    this.refreshToken = null;
    localStorage.removeItem(LocaleKeys.tokenId);
    localStorage.removeItem(LocaleKeys.refreshTokenId);
    localStorage.removeItem(LocaleKeys.tokenExpiresAt);
    localStorage.removeItem(LocaleKeys.authProfile);

    sessionStorage.removeItem(LocaleKeys.ancrageTodayShowed);
  }

  get loggedUserValue() {
    return this.loggedUser$.getValue();
  }

  isFullyRegistered() {
    if (
      this.loggedUserValue &&
      this.loggedUserValue.username &&
      this.loggedUserValue.email &&
      this.loggedUserValue.year &&
      this.loggedUserValue.cityfac &&
      this.loggedUserValue.fac
    ) {
      if (!this.isApplicationService.isAppIos()) {
        if (this.loggedUserValue.firstName && this.loggedUserValue.lastName) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  bootIntercomSession(id?: number) {
    (window as any).Intercom('boot', {
      app_id: environment.intercomAppId,
      language_override: environment.defaultLanguage,
      custom_launcher_selector: '#intercom_mobile_launcher',
      version: `${this.isApplicationService.isApplication() ? 'App' : 'Desktop'} ${
        environment.version
      }`,
      current_platform: this.isApplicationService.isApplication()
        ? this.isApplicationService.platform
        : 'web',
      user_id: id ? id : undefined,
      hide_default_launcher:
        this.isApplicationService.isMobileDevice ||
        window.innerWidth < screenWidthConstants.screenMD,
    });
  }

  private initSetScopeSettings() {
    this.loggedUser$
      .pipe(
        filter((loggedUser) => loggedUser !== null),
        tap((loggedUser) => {
          setTimeout(() => {
            const intercomData: any = {
              name: loggedUser.firstName + ' ' + loggedUser.lastName,
              user_id: loggedUser.id,
              pseudo: loggedUser.username,
              username: loggedUser.username,
              email: loggedUser.email,
              created_at: loggedUser.createdAt.getTime() / 1000,
              debut_abonnement: this.dateFormatPipe.transform(loggedUser.subStart.getTime()),
              start_subscription_at: loggedUser.subStart.getTime() / 1000,
              fin_abonnement: this.dateFormatPipe.transform(loggedUser.subEnd.getTime()),
              end_subscription_at: loggedUser.subEnd.getTime() / 1000,
              type_abonnement: this.currentPricing,
              subscription_type: this.currentPricing,
              adresse: loggedUser.address,
              address: loggedUser.address,
              code_postal: loggedUser.cp,
              zip_code: loggedUser.cp,
              ville: loggedUser.city,
              city: loggedUser.city,
              phone: loggedUser.cellphone,
              admin_user_url: `${this.languageService.adminUrl}/users/students/student-detail/${loggedUser.id}`,
              supermemo_enabled: loggedUser.supermemo_enabled,
              supermemo_migrated_at: loggedUser.supermemo_migrated_at
                ? loggedUser.supermemo_migrated_at.getTime() / 1000
                : null,
              supermemo_on: loggedUser.supermemo_on,
            };
            if (loggedUser.year) {
              intercomData.promo = loggedUser.year.name;
              intercomData.graduation_name = loggedUser.year.name;
            }
            if (localStorage.getItem(LocaleKeys.cookiesAccepted) !== '1') {
              intercomData.name = `HIDDEN-${loggedUser.id}`;
              intercomData.adresse = '-';
              intercomData.address = '-';
              intercomData.code_postal = '-';
              intercomData.zip_code = '-';
              intercomData.ville = '-';
              intercomData.city = '-';
              intercomData.phone = '-';
            }
            if (
              loggedUser.preferences?.block_marketing_mail ||
              loggedUser.preferences?.block_mail
            ) {
              intercomData.unsubscribed_from_emails = true;
            }
            (window as any).Intercom('update', intercomData);

            // Sentry.configureScope((scope) => {
            //   scope.setUser({
            //     id: loggedUser.id.toString(),
            //     username: loggedUser.username,
            //     email: loggedUser.email,
            //   });
            // });
          });
        })
      )
      .subscribe();
  }

  isPremiumPlus() {
    if (this.loggedUserValue) {
      const now = new Date();
      return (
        this.isElite() ||
        (this.loggedUserValue &&
          this.loggedUserValue.paid &&
          this.loggedUserValue.subEndMasterclass != null &&
          this.loggedUserValue.subEndMasterclass > now)
      );
    } else {
      return false;
    }
  }

  isElite() {
    if (this.loggedUserValue) {
      const now = new Date();
      return (
        this.loggedUserValue &&
        this.loggedUserValue.paid &&
        this.loggedUserValue.subEndElite != null &&
        this.loggedUserValue.subEndElite > now
      );
    } else {
      return false;
    }
  }

  get currentPricing(): 'elite' | 'premium-plus' | 'decouverte' {
    if (this.isElite()) {
      return 'elite';
    } else if (this.isPremiumPlus()) {
      return 'premium-plus';
    } else {
      return 'decouverte';
    }
  }

  isDaltonien() {
    return this.loggedUserValue && this.loggedUserValue.daltonien;
  }

  isRandomAnswers() {
    return this.loggedUserValue && this.loggedUserValue.randomAnswers;
  }

  facebookConnect(): Observable<boolean> {
    return new Observable((observer) => {
      this.facebookToEcniService.facebookLogin().subscribe((resLogin) => {
        if (resLogin) {
          if (
            resLogin.authResponse.userID === this.loggedUserValue.facebookId &&
            resLogin.authResponse.accessToken === this.loggedUserValue.facebookAccessToken
          ) {
            // si token + id déjà enregistrés et identiques: lancer l'import
            observer.next(true);
          } else {
            // si token pas enregistré ou différent:
            if (
              this.loggedUserValue.facebookId &&
              resLogin.authResponse.userID !== this.loggedUserValue.facebookId
            ) {
              // si user id déjà enregistré et différent > erreur

              const modalData: ModalWarningData = {
                title: this.translate.instant('util.error2'),
                content: this.translate.instant('facebook.error'),
                buttonText: this.translate.instant('util.ok'),
              };

              this.matDialog.open(ModalWarningComponent, {
                maxWidth: '100vw',
                data: modalData,
              });
              observer.error(this.translate.instant('facebook.error'));
            } else {
              // sinon : update paramètres facebook puis lancer la connexion
              this.loggedUserValue.facebookId = resLogin.authResponse.userID;
              this.loggedUserValue.facebookAccessToken = resLogin.authResponse.accessToken;

              this.httpUserService
                .facebookConnect(
                  this.loggedUserValue.facebookId,
                  this.loggedUserValue.facebookAccessToken
                )
                .subscribe((fbRes) => {
                  if (fbRes.success) {
                    this.updateUser();
                    observer.next(true);
                  }
                });
            }
          }
        }
      });
    });
  }

  facebookImport(): Observable<number> {
    return new Observable((observer) => {
      this.facebookConnect().subscribe(
        () => {
          this.httpSocialService.facebookImport().subscribe(
            (res) => {
              if (!res.success) {
                const modalData: ModalWarningData = {
                  title: this.translate.instant('util.error2'),
                  content: this.translate.instant('util.error') + '-errIMP1',
                  buttonText: this.translate.instant('util.ok'),
                };

                this.matDialog.open(ModalWarningComponent, {
                  maxWidth: '100vw',
                  data: modalData,
                });

                observer.error(this.translate.instant('util.error') + '-errIMP1');
              } else if (res.imported) {
                if (res.imported.length) {
                  this.toastr.success(
                    this.translate.instant('friend.import_ok', {
                      nb: res.imported.length,
                    })
                  );

                  observer.next(res.imported.length);
                } else {
                  this.toastr.warning(this.translate.instant('friend.import_0'));
                  observer.error(this.translate.instant('friend.import_0'));
                }
              }
            },
            () => {
              const modalData: ModalWarningData = {
                title: this.translate.instant('util.error2'),
                content: this.translate.instant('util.error') + '-errIMP2',
                buttonText: this.translate.instant('util.ok'),
              };

              this.matDialog.open(ModalWarningComponent, {
                maxWidth: '100vw',
                data: modalData,
              });

              observer.error(this.translate.instant('util.error') + '-errIMP2');
            }
          );
        },
        () => {
          observer.error();
        }
      );
    });
  }

  computeIntercomDisplay() {
    (window as any).Intercom('update', {
      hide_default_launcher:
        this.isApplicationService.isMobileDevice ||
        window.innerWidth < screenWidthConstants.screenMD,
    });
  }
}
