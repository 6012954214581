import { environment } from '../../../environments/environment';

export class UserEndpoints {
  static getOauth2Token = `${environment.api_base_url}/../oauth/v2/token`;
  static appleSignin = `${environment.api_base_url}/../oauth/v2/apple`;
  static googleSignin = `${environment.api_base_url}/../oauth/v2/google-token`;
  static userProfile = `${environment.api_base_url}/user/profile`;
  static register = `${environment.api_base_url}/register`;
  static sendResetEmail = `${environment.api_base_url}/resetting/send-mail`;
  static resetPassword = `${environment.api_base_url}/resetting/reset`;
  static confirmUser = `${environment.api_base_url}/register/confirm`;
  static checkEmail = `${environment.api_base_url}/user/check-email`;
  static checkUsername = `${environment.api_base_url}/user/check-username`;
  static user = `${environment.api_base_url}/user`;
  static searchUser = `${environment.api_base_url}/follow/user`;

  static screenshotTaken = `${environment.api_base_url}/user/scr/inc`;
  static facebookConnect = `${environment.api_base_url}/user/facebook/ids`;
  static resetData = `${environment.api_base_url}/user/data/reset`;
  static subscription = `${environment.api_base_url}/user/subscription`;
  static invoices = `${environment.api_base_url}/user/invoices`;
  static refunds = `${environment.api_base_url}/user/refunds`;
  static quit = `${environment.api_base_url}/user/quit`;

  static uploadAvatar = `${environment.api_base_url}/user/add-picture`;
  static deleteAvatar = `${environment.api_base_url}/user/picture`;

  static get(id: number) {
    return `${environment.api_base_url}/user/${id}/profile`;
  }
}
